<template>
    <div>
        {{ row.item.cash ? $t('common.button.yes') : $t('common.button.no') }}
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>